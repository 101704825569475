var statusCodes = {
    Ok: 200,
    NoContent: 204,
    BadRequest: 400,
    Unauthorized: 401,
    PaymentRequired: 402,
    Conflict: 409,
    Forbidden: 403,
    InternalServerError: 500
};

var DEFAULT_PAGE_SIZE = 25;

(function ($, window) {

    $.fn.contextMenu = function (settings) {

        return this.each(function () {

            // Open context menu
            $(this).on("click", function (e) {
                // return native menu if pressing control
                if (e.ctrlKey) return;
                //open menu
                var $menu = $(settings.menuSelector)
                    .data("invokedOn", $(e.target))
                    .show()
                    .css({
                        position: "absolute",
                        left: getMenuPosition(e.clientX, 'width', 'scrollLeft'),
                        top: getMenuPosition(e.clientY, 'height', 'scrollTop')
                    })
                    .off('click')
                    .on('click', 'a', function (e) {
                        $menu.hide();

                        var $invokedOn = $menu.data("invokedOn");
                        var $selectedMenu = $(e.target);

                        settings.menuSelected.call(this, $invokedOn, $selectedMenu);
                    });

                return false;
            });

            //make sure menu closes on any click
            $(document).click(function () {
                $(settings.menuSelector).hide();
            });
        });

        function getMenuPosition(mouse, direction, scrollDir) {
            var win = $(window)[direction](),
                scroll = $(window)[scrollDir](),
                menu = $(settings.menuSelector)[direction](),
                position = mouse + scroll;

            // opening menu would pass the side of the page
            if (mouse + menu > win && menu < mouse)
                position -= menu;

            return position;
        }

    };
})(jQuery, window);

var eleventAdmin = { Id: 1, Name: 'Elevent Administrator', Description: 'Super user account for Elevent employees. Has access to all functionalities for all clients.'};
var eleventMgr = { Id: 2, Name: 'Elevent Manager', Description: 'Elevent manager with elevated access to functionalities for all clients.'};
var eleventUser = { Id: 3, Name: 'Elevent User', Description: 'Elevent employee with access to functionalities for all clients.'};
var clientAdmin = { Id: 4, Name: 'Administrator', Description: 'Administrator of the account. Has access to all account functionalities in the Back Office, Box Office, and Entry.'};
var backOfficeMgr = { Id: 5, Name: 'Manager', Description: 'Account manager with access to elevated functionalities in the Back Office, Box Office, and Entry.'};
var backOfficeUser = { Id: 6, Name: 'Staff', Description: 'Account staff member with basic access to the Back Office, Box Office, and Entry.'};
var boxOfficeMgr = { Id: 7, Name: 'Box Office Manager', Description: 'User with elevated access to the Box Office.'};
var reportViewer = { Id: 8, Name: 'Report Viewer', Description: 'User with access to reports only.'};
var boxOfficeUser = { Id: 9, Name: 'Box Office Agent', Description: 'User with basic access to the Box Office.'};

var permissions = [
    { Id: 1, Permissions: [eleventAdmin, eleventMgr, eleventUser, clientAdmin, backOfficeMgr, backOfficeUser, boxOfficeMgr, boxOfficeUser, reportViewer]},
    { Id: 2, Permissions: [eleventMgr, eleventUser, clientAdmin, backOfficeMgr, backOfficeUser, boxOfficeMgr, boxOfficeUser, reportViewer]},
    { Id: 3, Permissions: [eleventUser, clientAdmin, backOfficeMgr, backOfficeUser, boxOfficeMgr, boxOfficeUser, reportViewer]},
    { Id: 4, Permissions: [clientAdmin, backOfficeMgr, backOfficeUser, boxOfficeMgr, boxOfficeUser, reportViewer]},
    { Id: 5, Permissions: [backOfficeMgr, backOfficeUser, boxOfficeMgr, boxOfficeUser, reportViewer]},
    { Id: 6, Permissions: [backOfficeUser, boxOfficeMgr, boxOfficeUser]},
	{ Id: 7, Permissions: [boxOfficeMgr, boxOfficeUser, reportViewer]},
	{ Id: 8, Permissions: [reportViewer]},
	{ Id: 9, Permissions: [boxOfficeUser]},
];

function displayPermission(permission, clientId) {
    switch(permission) {
        case 1:
            return "Elevent Administrator";
        case 2:
            return "Elevent Manager";
        case 3:
            return "Elevent User";
        case 4:
            return "Administrator";
        case 5:
            return "Manager";
        case 6:
            return "Staff";
		case 7: 
			return "Box Office Manager";
		case 8: 
			return "Report Viewer";
		case 9: 
			return "Box Office Agent";
        default:
            return "Unknown";
    }
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

$(function () {
    $(".dropdown-menu > li > a.trigger").on("click", function (e) {
        var current = $(this).next();
        var grandparent = $(this).parent().parent();
        if ($(this).hasClass('left-caret') || $(this).hasClass('right-caret'))
            $(this).toggleClass('right-caret left-caret');
        grandparent.find('.left-caret').not(this).toggleClass('right-caret left-caret');
        grandparent.find(".sub-menu:visible").not(current).hide();
        current.toggle();
        e.stopPropagation();
    });
    $(".dropdown-menu > li > a:not(.trigger)").on("click", function () {
        var root = $(this).closest('.dropdown');
        root.find('.left-caret').toggleClass('right-caret left-caret');
        root.find('.sub-menu:visible').hide();
    });
});

function ShowLoadingOverlay() {
	$("div.loading-spinner-overlay").show();
}

function HideLoadingOverlay() {
	$("div.loading-spinner-overlay").hide();
}

function GetDateString(date) {
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
}

function GetDateTimeParts(dt) {
    return {
        Month: moment(dt).month() + 1,
        Day: moment(dt).date(),
        Year: moment(dt).year(),
        Hour: moment(dt).hour(),
        Minute: moment(dt).minute(),
        Second: 0,
    };
}

function GetDateTimeFromParts(parts) {
	return moment({'month': parts.Month-1, 'date': parts.Day, 'year': parts.Year, 'Hour': parts.Hour, 'Minute': parts.Minute}).toDate();
}

function ShowSessionExpireModal(response) {
	if (response.indexOf('Anti forgery') >= 0)                        
		$("#session-expire-modal").modal({ backdrop: 'static', keyboard: false });
}

var STATUS = {
    Active: 1,
    Inactive: 2,
    Cancelled: 3,
    Pending: 4,
    Completed: 5,
	Archived: 8,
};

var MODULES = {
	TicketPackage: 1,
	Pass: 2,
	Donation: 3,
	Membership: 4,
	Merchandise: 5,
	Extra: 6,
	Allocation: 7,
	MultiSection: 8,
	ReservedSeating: 9,
	Credential: 10,
	Concession: 11,
	Api: 12,
	ExternalWeb: 13,
	Fandango: 14,
	Tipping: 15,
	Brands: 16,
	BoxOfficeReporting: 17,
	Wrstbnd: 18,
};

var TRANSACTION_TYPES = {
	Sale: 1,
	Refund: 2,
	Cancel: 3,
	Exchange: 4,
};

var TRANSACTION_TYPES_OPTIONS = [
	{ Value: 1, Text: 'Sale' },
	{ Value: 2, Text: 'Refund' },
	{ Value: 3, Text: 'Cancel' },
	{ Value: 4, Text: 'Exchange' },
];

var TAX_TYPE = {
	Taxable: 1,
	NonTaxable: 2,
	TaxIncluded: 3,
};

var TAX_TYPE_OPTIONS = [
	{ Value: '1', Text: 'Taxable'},
	{ Value: '2', Text: 'Do not tax'},
	{ Value: '3', Text: 'Tax is included'}
];

var TICKET_TYPE = {
	Standard: 1,
	Virtual : 2,
	BoxOffice: 3,
	Fandango: 4,
};

var TICKET_TYPE_OPTIONS = [
	{ Value: '1', Text: 'Standard' },	
	{ Value: '2', Text: 'Virtual' },
	{ Value: '3', Text: 'Box Office' },
	{ Value: '4', Text: 'Fandango' },
];

var EVENT_DETAIL_TYPES = {
    Synopsis: 1,
    LengthInMinutes: 2,
    Star: 3,
    Director: 4,
    Writer: 5,
    Rating: 6,
    PosterImage: 7,
    CustomEventHtmlContent: 8,    
	SearchKeywords: 9,
	Biography: 10,
	Support: 11,
	ZeroPricedTicketDisplay: 12,
	FacebookUrl: 13,
	WebsiteUrl: 14,
	TwitterUrl: 15,
	InstagramUrl: 16,
	CityState: 17,
	Playwright: 18,
	Music: 19,
	Book: 20,
	Lyrics: 21,
	Choreographer: 22,
	YouTubeEmbed: 23,
	Headliner: 24,
	ExternalTicketButtonText: 25,
	ExternalTicketLink: 26,
	ScanType: 27,
	DefaultCineSendAssetId: 28,
	Origin: 29,
	ReleaseYear: 30,
	Subtitles: 31,
	Producer: 32,
	Distributor: 33,
	Genre: 34,
	Section: 35,
	Subtitle: 36,
	Languages: 37,
	Cinematographer: 38,
	Editing: 39,
	Sound: 40,
	Composer: 41, 
	CoProducer: 42,
	ExecutiveProducer: 43,
	TrailerUrl: 44,
	PremiereStatus: 45,
	Format: 46,
	TmdbId: 47,
	Logline: 48,
	TmdbPosterImage: 49
};

var EVENT_DETAIL_TYPE_OPTIONS = [
	{ Value: 1, Text: 'Synopsis', IsSetting: false },
	{ Value: 2, Text: 'LengthInMinutes', IsSetting: true },
	{ Value: 3, Text: 'Cast', IsSetting: false },
	{ Value: 4, Text: 'Director', IsSetting: false },
	{ Value: 5, Text: 'Writer', IsSetting: false },
	{ Value: 6, Text: 'Rating', IsSetting: false },
	{ Value: 7, Text: 'Image', IsSetting: false },
	{ Value: 8, Text: 'Custom HTML', IsSetting: true },
	{ Value: 9, Text: 'Search Keywords', IsSetting: true },
	{ Value: 10, Text: 'Biography', IsSetting: false },
	{ Value: 11, Text: 'Support', IsSetting: false },
	{ Value: 12, Text: 'Zero Priced Ticket Display', IsSetting: true },
	{ Value: 13, Text: 'Facebook', IsSetting: false },
	{ Value: 14, Text: 'Website', IsSetting: false },
	{ Value: 15, Text: 'Twitter', IsSetting: false },
	{ Value: 16, Text: 'Instagram', IsSetting: false },
	{ Value: 17, Text: 'City / State', IsSetting: false },
	{ Value: 18, Text: 'Playwright', IsSetting: false },
	{ Value: 19, Text: 'Music', IsSetting: false },
	{ Value: 20, Text: 'Book', IsSetting: false },
	{ Value: 21, Text: 'Lyrics', IsSetting: false },
	{ Value: 22, Text: 'Choreographer', IsSetting: false },	
	{ Value: 23, Text: 'YouTubeEmbed', IsSetting: false },
	{ Value: 24, Text: 'Headliner', IsSetting: false },
	{ Value: 25, Text: 'External Ticket Button Text', IsSetting: true },
	{ Value: 26, Text: 'External Ticket Link', IsSetting: true },
	{ Value: 27, Text: 'ScanType', IsSetting: true },
	{ Value: 28, Text: 'Asset Id', IsSetting: true },
	{ Value: 29, Text: 'Origin (Country/Region)', IsSetting: false },
	{ Value: 30, Text: 'Release Date', IsSetting: false },
	{ Value: 31, Text: 'Subtitles', IsSetting: false },
	{ Value: 32, Text: 'Producer', IsSetting: false },
	{ Value: 33, Text: 'Distributor', IsSetting: true },
	{ Value: 34, Text: 'Genre', IsSetting: false },
	{ Value: 35, Text: 'Section', IsSetting: false },
	{ Value: 36, Text: 'Subtitle', IsSetting: false },
	{ Value: 37, Text: 'Languages', IsSetting: false },
	{ Value: 38, Text: 'Cinematographer', IsSetting: false },
	{ Value: 39, Text: 'Editing', IsSetting: false },
	{ Value: 40, Text: 'Sound', IsSetting: false },
	{ Value: 41, Text: 'Composer', IsSetting: false },	
	{ Value: 42, Text: 'Co Producer', IsSetting: false },	
	{ Value: 43, Text: 'Executive Producer', IsSetting: false },	
	{ Value: 44, Text: 'Trailer Url', IsSetting: false },	
	{ Value: 45, Text: 'Premiere Status', IsSetting: false },	
	{ Value: 46, Text: 'Format', IsSetting: false },
	{ Value: 48, Text: 'Logline', IsSetting: false},
	{ Value: 49, Text: 'TmdbPosterImage', IsSetting: false}
];

var EVENT_DATA_ATTRIBUTES = [
	// EVENT_DETAIL_TYPES.Star,
	// EVENT_DETAIL_TYPES.Director,
	// EVENT_DETAIL_TYPES.Writer,
	// EVENT_DETAIL_TYPES.Rating,
	// EVENT_DETAIL_TYPES.PosterImage,
	EVENT_DETAIL_TYPES.Biography,
	EVENT_DETAIL_TYPES.Support,
	EVENT_DETAIL_TYPES.CityState,
	EVENT_DETAIL_TYPES.Music,
	EVENT_DETAIL_TYPES.Book,
	EVENT_DETAIL_TYPES.Lyrics,
	EVENT_DETAIL_TYPES.Origin,
	EVENT_DETAIL_TYPES.Choreographer,
	EVENT_DETAIL_TYPES.ReleaseYear,
	EVENT_DETAIL_TYPES.Subtitles,
	EVENT_DETAIL_TYPES.Producer,	
	EVENT_DETAIL_TYPES.WebsiteUrl,		
];

var EVENT_MEDIA_ATTRIBUTES = [
	EVENT_DETAIL_TYPES.FacebookUrl,
	EVENT_DETAIL_TYPES.TwitterUrl,
	EVENT_DETAIL_TYPES.InstagramUrl,	
];

var EVENT_API_ATTRIBUTES = [
	EVENT_DETAIL_TYPES.Genre,
	EVENT_DETAIL_TYPES.Section,
	EVENT_DETAIL_TYPES.Subtitle,
	EVENT_DETAIL_TYPES.Languages,
	EVENT_DETAIL_TYPES.Cinematographer,
	EVENT_DETAIL_TYPES.Editing,
	EVENT_DETAIL_TYPES.Sound,
	EVENT_DETAIL_TYPES.Composer,
	EVENT_DETAIL_TYPES.CoProducer,
	EVENT_DETAIL_TYPES.ExecutiveProducer,
	EVENT_DETAIL_TYPES.TrailerUrl,		
	EVENT_DETAIL_TYPES.PremiereStatus,	
	EVENT_DETAIL_TYPES.TmdbPosterImage,
	EVENT_DETAIL_TYPES.Logline,	
];

var EVENT_TYPES = {
	Uncategorized: 0,
	Film: 1,
	CharityEvent: 2,
	Panel: 3,
	ShortsProgram: 4,
	Documentary: 5,
	Party: 6,
	Centerpiece: 7,
	SpotlightFilm: 8,
	OpeningNight: 9,
	ClosingNight: 10,
	FamilyFilm: 11,
	AwardsGala: 12,
	Workshop: 13,
	Gala: 14,
	Class: 15,
	Conference: 16,
	ClassPanel: 17,
	Band: 18,
	SoloPerformance: 19,
	LiveTheatre: 20,
	MusicalTheatre: 21,
	Dance: 22,
	Comedy: 23,
	Cabaret: 24,
	FringeBand: 25,
};

var EVENT_TYPES_OPTIONS = [
	{ Value: 0, Text: 'Uncategorized', ClientTypeId: 0 },
	{ Value: 1, Text: 'Film', ClientTypeId: 1 },
	{ Value: 2, Text: 'Charity Event', ClientTypeId: 2 },
	{ Value: 3, Text: 'Panel', ClientTypeId: 1 },
	{ Value: 4, Text: 'Shorts Program', ClientTypeId: 1 },
	{ Value: 5, Text: 'Documentary', ClientTypeId: 1 },
	{ Value: 6, Text: 'Party', ClientTypeId: 1 },
	{ Value: 7, Text: 'Centerpiece', ClientTypeId: 1 },
	{ Value: 8, Text: 'Spotlight Film', ClientTypeId: 1 },
	{ Value: 9, Text: 'Opening Night', ClientTypeId: 1 },
	{ Value: 10, Text: 'Closing Night', ClientTypeId: 1 },
	{ Value: 11, Text: 'Family Film', ClientTypeId: 1 },
	{ Value: 12, Text: 'Awards Gala', ClientTypeId: 1 },
	{ Value: 13, Text: 'Workshop', ClientTypeId: 1 },	
	{ Value: 14, Text: 'Gala', ClientTypeId: 1 },
	{ Value: 15, Text: 'Class', ClientTypeId: 3 },
	{ Value: 16, Text: 'Conference', ClientTypeId: 3 },
	{ Value: 17, Text: 'Panel', ClientTypeId: 3 },
	{ Value: 18, Text: 'Band', ClientTypeId: 4 },
	{ Value: 19, Text: 'Solo Performance', ClientTypeId: 5 },
	{ Value: 20, Text: 'Live Theatre', ClientTypeId: 5 },
	{ Value: 21, Text: 'Musical Theatre', ClientTypeId: 5 },
	{ Value: 22, Text: 'Dance', ClientTypeId: 5 },
	{ Value: 23, Text: 'Comedy', ClientTypeId: 5 },
	{ Value: 24, Text: 'Cabaret', ClientTypeId: 5 },
	{ Value: 25, Text: 'Band', ClientTypeId: 5 },
];

var CINESEND_EVENT_KEYS = {
	AssetId: 'video_id',
	Title: 'title',
	Logline: 'logline',
	ReleaseYear: 'release_year',
	Directors: 'directors',
	Producers: 'producers',
	Countries: 'countries',
	Subtitles: 'subtitles',
	ShortSummary: 'short_summary',
	Languages: 'languages',	
	BackgroundImage: 'background_image_url',
	ThumbnailImage: 'thumbnail_url'
};

var TICKET_PACKAGE_DETAIL_TYPES = {
    AdditionalInformation: 1,
	IsGiftable: 2,
	GlAccountCode: 3
};

var TICKET_PASS_DETAIL_TYPES = {
    AdditionalInformation: 1,
	IsGiftable: 2,
	GlAccountCode: 3
};

var EXTRA_DETAIL_TYPES = {
    AdditionalInformation: 1,
	IsGiftable: 2,
	GlAccountCode: 3
};

var MEMBERSHIP_PROGRAM_DETAIL_TYPES = {
    AdditionalInformation: 1,
	DonationName: 2,
	DonationAmount: 3,
	EmployerId: 4,
	NotUsed: 5,
	RenewalGracePeriod: 6,
	DonorPerfectCampaignCode: 7,
	DonorPerfectGlCode: 8,
	DonorPerfectSolicitationCode: 9,
	DonorPerfectSubSolicitationCode: 10,
	DonorPerfectTypeOfGiftCode: 11,
	AutoGenerateDonationReceipt: 12,
	PrimaryMembershipProgramId: 13,
	UpgradeMembershipProgramId: 14,
	MinimumPriceValue: 15,
	MaximumPriceValue: 16,
	IsAutoRenew: 17,
	RenewalNoticeLeadTime: 18,
	RenewalExecutionLeadTime: 19,
	IsGiftable: 20,
	GlAccountCode: 21
};

var SERVICE_CHARGE_TYPES = {
	PerItemDollar: 1,
	PerItemPercentage: 2,
	PerTransactionDollar: 3,
	PerTransactionPercentage: 4,
};

var ELEVENT_FEE_TYPES = {
	AbsorbNone: 1,
	AbsorbWebOnly: 2,
	AbsorbBoxOfficeOnly: 3,
	AbsorbWebAndBoxOffice: 4,	
};

var ELEVENT_FEE_OPTIONS = [
	{ Value: 0, Text: 'Charge the fee to the customer' },
	{ Value: 1, Text: 'Charge the fee to you' }
];

function GetItemFeeType(isAbsorbWeb, isAbsorbBoxOffice) {
	var feeType = ELEVENT_FEE_TYPES.AbsorbNone;
	if (isAbsorbWeb === '1' && isAbsorbBoxOffice === '1')
		feeType = ELEVENT_FEE_TYPES.AbsorbWebAndBoxOffice;
	else if (isAbsorbWeb === '1')
		feeType = ELEVENT_FEE_TYPES.AbsorbWebOnly;
	else if (isAbsorbBoxOffice === '1')
		feeType = ELEVENT_FEE_TYPES.AbsorbBoxOfficeOnly;
	return feeType;
};

var SERVICE_CHARGE_TYPES_OPTIONS = [
	{ Value: 1, Text: 'Per Item By Dollar' },
	{ Value: 2, Text: 'Per Item By Percentage' },
	{ Value: 3, Text: 'Per Transaction By Dollar' },
	{ Value: 4, Text: 'Per Transaction By Percentage' },
];

var DELIVERY_METHODS = [
	{ Value: 1, Text: 'Electronic' },
	{ Value: 2, Text: 'FedEx' },
	{ Value: 3, Text: 'Will Call'},
	{ Value: 4, Text: 'Standard Mail' },
	{ Value: 5, Text: 'FedEx 2-Day' },
	{ Value: 6, Text: 'FedEx Priority Overnight' },
	{ Value: 7, Text: 'Delivered in Person' },
	{ Value: 8, Text: 'Print at Home'}
];

var SHOPPING_CART_TYPES = {
	Ticket: 1,
	TicketPackage: 2,
	TicketPass: 3,
	Donation: 4,
	Membership: 5,
	Merchandise: 6,
	Extra: 7,
	WalkupTicket: 8,
	Concession: 9
};

var BARCODE_TYPE_OPTIONS = [
	{ Value: '1', Text: 'Aztec'},
	{ Value: '2', Text: 'Code 128'},	
	{ Value: '3', Text: 'QR Code'},	
];

var CREDENTIAL_DETAIL_TYPES = {
	Photo: 1,
	FirstName: 2,
	LastName: 3,
	PhoneNumber: 4,
	Organization: 5,
	JobTitle: 6,
	StaffJobTitle: 7,
	StaffDepartment: 8,
	FilmTitle: 9,
	EmailAddress: 10,
};

var CREDENTIAL_DETAIL_TYPES_OPTIONS = [
	{ Value: '1', Text: 'Photo'},
	{ Value: '2', Text: 'First Name'},
	{ Value: '3', Text: 'Last Name'},
	{ Value: '4', Text: 'Phone Number'},
	{ Value: '5', Text: 'Organization'},
	{ Value: '6', Text: 'Job Title'},
	{ Value: '7', Text: 'Staff Job Title'},
	{ Value: '8', Text: 'Staff Department'},
	{ Value: '9', Text: 'Film Title'},
	{ Value: '10', Text: 'Email Address'},
];

var PROCESSING_STEP_LOCATIONS = {
	Preparing: 0,
	Payment: 1,
	Commit: 2,
	Finalize: 3,
	Complete: 4,
};


var PAYMENT_TYPES = {
	Unknown: 0,
	Cash: 1,
	AmericanExpress: 2,
	DinersClub: 5,
	Discover: 6,
	JCB: 7,
	Maestro: 9,
	MasterCard: 10,
	Visa: 13,
	External: 14,
	ACH: 15,
	Check: 16,
	Fandango: 17
};

function GetPaymentTypeFromString(str) {
	switch(str) {
		case 'visa': 
			return PAYMENT_TYPES.Visa;
		case 'mastercard': 
			return PAYMENT_TYPES.MasterCard;
		case 'amex': 
			return PAYMENT_TYPES.AmericanExpress;
		case 'discover': 
			return PAYMENT_TYPES.Discover;
		case 'jcb': 
			return PAYMENT_TYPES.JCB;
		case 'diners': 
			return PAYMENT_TYPES.DinersClub;
		case 'external': 
			return PAYMENT_TYPES.External;
		case 'cash': 
			return PAYMENT_TYPES.Cash;
		case 'ach':
			return PAYMENT_TYPES.ACH;
		case 'check': 
			return PAYMENT_TYPES.Check;
		case 'fandango':
			return PAYMENT_TYPES.Fandango;
		default:
			return PAYMENT_TYPES.Unknown;
	};
};

var DISTRIBUTORS = {
	FoxFSL : 1,
	Disney: 2,
	Sony: 3,
	Paramount: 4,
	Lionsgate: 5,
	UniversalFocusOTL: 6,
	UnitedArtistsReleasing: 7,
	STX: 8,
	A24: 9,
	Amazon: 10,
	BleeckerStreet: 11,
	MusicBoxFilms: 12,
	RoadsideAtrractions: 13,
	NeonRated: 14,
	Magnolia: 15,
	IFC: 16,
	Greenwich: 17,
	EntertainmentStudiosMotionPictures: 18,
	Briarcliff: 19,
	Funimation: 20,
	WarnerBrothers: 21,
	Netflix: 22,
	Array: 23,
	AGFA: 24,
	WiseauFilms: 25,
	ParkCircus: 26,
	SonyPictureClassics: 27,
	Gkids: 28,
	JanusFilms: 29,
	Rialto: 30,
	Swank: 31,
	Independent: 32,
	CinemaGuild: 33,
	CircleCollective: 34,
	DarkStarPics: 35,
	ElevationPictures: 36,
	FilmMovement: 37,
	FilmsWeLike: 38,
	GameTheory: 39,
	Grasshopper: 40,
	// GKids: 41,
	Icarus: 42,
	KimStim: 43,
	KinoLorber: 44,
	levelFILM: 45,
	Maison43: 46,
	MK2MileEnd: 47,
	MongrelMedia: 48,
	Mubi: 49,
	NationalFilmBoard: 50,
	Oscilloscope: 51,
	OutsiderPictures: 52,
	PhotonFilms: 53,
	RavenBanner: 54,
	RialtoPictures: 55,
	StrandReleasing: 56,
	TuckmanMedia: 57,
	UnobstructedView: 58,
	VisitFilms: 59,
	Vortex: 60,
	VVS: 61,
	WellgoUSA: 62,
	Zeitgeist: 63,
	Utopia: 64,
	FathomEvents: 65,
	EmickMedia: 66,
	KetchupEntertainment: 67,
	CohenMedia: 68,
	MenemshaFilms: 69,
	Myriad: 70,
	FirstRunFeatures: 71,
};

var DISTRIBUTOR_OPTIONS = [
{'Value': 1, Text: 'Fox/FSL'},
{'Value': 2, Text: 'Disney'},
{'Value': 3, Text: 'Sony'},
{'Value': 4, Text: 'Paramount'},
{'Value': 5, Text: 'Lionsgate'},
{'Value': 6, Text: 'Universal/Focus/OTL'},
{'Value': 7, Text: 'United Artists Releasing'},
{'Value': 8, Text: 'STX'},
{'Value': 9, Text: 'A24'},
{'Value': 10, Text: 'Amazon'},
{'Value': 11, Text: 'Bleecker Street'},
{'Value': 12, Text: 'Music Box Films'},
{'Value': 13, Text: 'Roadside Atrractions'},
{'Value': 14, Text: 'Neon Rated'},
{'Value': 15, Text: 'Magnolia'},
{'Value': 16, Text: 'IFC'},
{'Value': 17, Text: 'Greenwich'},
{'Value': 18, Text: 'Entertainment Studios Motion Pictures'},
{'Value': 19, Text: 'Briarcliff'},
{'Value': 20, Text: 'Funimation'},
{'Value': 21, Text: 'Warner Brothers'},
{'Value': 22, Text: 'Netflix'},
{'Value': 23, Text: 'Array'},
{'Value': 24, Text: 'AGFA (American Genre Film Archive)'},
{'Value': 25, Text: 'Wiseau Films'},
{'Value': 26, Text: 'Park Circus'},
{'Value': 27, Text: 'Sony Pictures Classics'},
{'Value': 28, Text: 'Gkids'},
{'Value': 29, Text: 'Janus Films'},
{'Value': 30, Text: 'Rialto'},
{'Value': 31, Text: 'Swank'},
{'Value': 32, Text: 'Independent'},
{'Value': 33, Text: 'Cinema Guild'},
{'Value': 34, Text: 'Circle Collective'},
{'Value': 35, Text: 'Dark Star Pics'},
{'Value': 36, Text: 'Elevation Pictures'},
{'Value': 37, Text: 'Film Movement'},
{'Value': 38, Text: 'FilmsWeLike'},
{'Value': 39, Text: 'Game Theory'},
{'Value': 40, Text: 'Grasshopper'},
// {'Value': 41, Text: 'GKids'},
{'Value': 42, Text: 'Icarus'},
{'Value': 43, Text: 'KimStim'},
{'Value': 44, Text: 'Kino Lorber'},
{'Value': 45, Text: 'levelFILM'},
{'Value': 46, Text: 'Maison 4:3'},
{'Value': 47, Text: 'MK2 Mile-End'},
{'Value': 48, Text: 'Mongrel Media'},
{'Value': 49, Text: 'Mubi'},
{'Value': 50, Text: 'National Film Board'},
{'Value': 51, Text: 'Oscilloscope'},
{'Value': 52, Text: 'Outsider Pictures'},
{'Value': 53, Text: 'Photon Films'},
{'Value': 54, Text: 'Raven Banner'},
{'Value': 55, Text: 'Rialto Pictures'},
{'Value': 56, Text: 'Strand Releasing'},
{'Value': 57, Text: 'Tuckman Media'},
{'Value': 58, Text: 'Unobstructed View'},
{'Value': 59, Text: 'Visit Films'},
{'Value': 60, Text: 'Vortex'},
{'Value': 61, Text: 'VVS'},
{'Value': 62, Text: 'Wellgo USA'},
{'Value': 63, Text: 'Zeitgeist'},
{'Value': 64, Text: 'Utopia'},
{'Value': 65, Text: 'Fathom Events'},
{'Value': 66, Text: 'Emick Media' },
{'Value': 67, Text: 'Ketchup Entertainment' },
{'Value': 68, Text: 'Cohen Media' },
{'Value': 69, Text: 'Menemsha Films' },
{'Value': 70, Text: 'Myriad' },
{'Value': 71, Text: 'First Run Features' },
];

function ScriptTagDecode(markup) {
	return markup != null && markup.length > 0 ? markup.replace("<scripttag>", "<script>").replace("</scripttag>", "</script>") : markup;
}

function getQueryStringParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function groupBy (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};